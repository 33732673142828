import React, { useEffect, useState } from "react";
import ParseTemplate from "./ParseTemplate";
import { fetchMicroSiteDataMatchingDomain } from "../utils/fetch";
import { useTheme } from '../ThemeContext';
import NotFound from "./NotFound";


const MicrositeHome = () => {

    const [microSiteData, setMicroSiteData] = useState({});
    const { setTheme } = useTheme("theme2");



    useEffect(() => {
        async function loadMicroSiteData() {
            try {
              const data = await fetchMicroSiteDataMatchingDomain();
              setMicroSiteData(data);
              setTheme(data.themeType === 'theme1-dark' ? 'theme1' : 'theme2'); // Assuming themeType is 'theme1' or 'theme2'
            } catch (error) {
              console.error('Failed to fetch microsite data:', error);
            }
          }
          
          loadMicroSiteData();
    }, []);
return(
    microSiteData ? <ParseTemplate data={microSiteData}/> : <NotFound />
)
}

export default MicrositeHome;
