import React, { useEffect, useState } from "react";
import PageWithRightRail from "./PageWithRightRail";
import FullWidthPage from "./FullWidthPage";

const ParseTemplate = ({data}) => {
  const [microSiteData, setMicroSiteData] = useState(null);
  const [bundleData, setBundleData] = useState(null);
  const [navItems, setNavItems] = useState([]);
  const [footerItems, setFooterItems] = useState([]);
  const [favIconUrl, setFavIconUrl] = useState('');


  useEffect(() => {
         setMicroSiteData(data);

  }, [data]);

  useEffect(() => {
    if (microSiteData) {
      const url = `${microSiteData.contentAPI}`;
      const headers = new Headers({
        Accept: "application/vnd.api+json",
      });

      fetch(url, { headers })
        .then((response) => response.json())
        .then((data) => {
          const matchNodeIDData = data.data.find(
            (ele) => ele.attributes.drupal_internal__nid == microSiteData.nodeID
          );
          if (matchNodeIDData) {
            setBundleData(matchNodeIDData);
            getNavData();
            getFooterData();
            setFavIconUrl(microSiteData?.favIcon);
          }
        })
        .catch((err) =>
          console.log("There was an error accessing the API", err)
        );
    }
  }, [microSiteData]);

  useEffect(()=>{
    if (favIconUrl) {
      const link = document.createElement('link');
      const oldLink = document.querySelector("link[rel='icon']");

      link.rel = 'icon';
      link.href = favIconUrl;

      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      
      document.head.appendChild(link);
    }

  },[favIconUrl])

  const getNavData = () => {
    const url = `${microSiteData.headerMenuAPI}`;
    const headers = new Headers({
      Accept: "application/vnd.api+json",
    });

    fetch(url, { headers })
      .then((response) => response.json())
      .then((data) => {
        setNavItems(data.data);
      })
      .catch((err) => console.log("There was an error accessing the API", err));
  };

  const getFooterData= () => {
    const url = `${microSiteData.footerMenuAPI}`;
    const headers = new Headers({
      Accept: "application/vnd.api+json",
    });

    fetch(url, { headers })
      .then((response) => response.json())
      .then((data) => {
        setFooterItems(data.data);
      })
      .catch((err) => console.log("There was an error accessing the API", err));
  };
  return (
    <>
      {navItems && navItems.length > 0 && (
        <header className="container header-container">
          <nav aria-label="primary">
            <div className="flex items-center justify-between h-12 mt-3">
              <div
                id="branding"
                className="inline-flex h-12 m-0 flex-shrink-0 bg-yellow-500"
                aria-hidden="false"
              >
                <a className="flex flex-shrink-0 items-center px-4" href={window.location.href}>
                  <img
                    alt="Edward Jones"
                    className="block w-32"
                    src={`${microSiteData.logo}`}
                    loading="lazy"
                  />
                </a>
              </div>
              <div id="rightSection" className="flex items-center" aria-hidden="false">
                <div className="flex-1 mr-6 ej">
                  <div data-testid="dropdown-nav">
                    <ul className="flex -mx-3">
                      {navItems.map((ele, index) => {
                        return (
                          <li key={index}>
                            <a
                              href={ele.attributes.url}
                              className="relative nav-tabs-item h-12 px-1 mx-2 text-sm leading-tight text-black"
                            >
                              {ele.attributes.title}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  
                </div>
                <div className="flex items-center flex-shrink-0 ">
                  <a
                    className="text-sm items-center whitespace-nowrap btn btn-secondary px-3.5 cursor-pointer"
                    href="https://onlineaccess.edwardjones.com/app/oa-login"
                    target="_blank"
                  >
                    <span className="w-auto">Secure Login</span>
                  </a>
                  <span className="ml-3">
                    <a
                      className="btn btn-primary px-5 text-sm"
                      href="/us-en/search/financial-advisor/results"
                    >
                      Find a Financial Advisor
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </nav>
        </header>
      )}
      {bundleData && (
        <div className="content">
          {(() => {
            switch (microSiteData?.bundleType) {
              case "ej_micro_site":
                return <FullWidthPage bundleData={bundleData} />;
              case "right_rail":
                return <PageWithRightRail bundleData={bundleData} />;
              default:
                return null;
            }
          })()}
        </div>
      )}
      {footerItems && footerItems.length > 0 && <footer className="bg-gray-500 text-gray-100 py-10 ">
        <div className="container">
          <ul className="flex -mx-3">
          {footerItems.map((ele, index) => {
                        return (
                          <li className="my-3 px-6 text-lg font-bold" key={index}>
                            <a
                              href={ele.attributes.url}
                              className="relative nav-tabs-item h-12 px-1 mx-2 leading-tight text-black"
                            >
                              {ele.attributes.title}
                            </a>
                          </li>
                        );
                      })}
          </ul>
          </div></footer>}
    
    </>
  );
};

export default ParseTemplate;
