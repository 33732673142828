import { REACT_APP_DRUPAL_BASE_URL } from "./config";
import { getSubdomain } from "./utils";

function normalizeKey(key) {
  return key.toLowerCase().replace(/\s+/g, '-');  // Convert spaces to hyphens and make lowercase
}

export const fetchMicroSiteDataMatchingDomain = async () => {
  const subdomain = getSubdomain();
      const response = await fetch(`${REACT_APP_DRUPAL_BASE_URL}/v1/api/microsite-config`);
      const data = await response.json();
      let matchedData;
      if(data) {
         matchedData = data.find(item => normalizeKey(item.micrositeName) === subdomain);
      }

        return (matchedData);

};