import React from "react";
import { REACT_APP_DRUPAL_BASE_URL } from "../utils/config";

const ImageWithLink = ({ uri, title, imgAlt, imgSrc }) => {
  return (
    <div className="border-gray-200 w-100 p-2">
      {imgAlt && (
        <div className="w-100">
          <img src={`${REACT_APP_DRUPAL_BASE_URL}${imgSrc}`} alt={imgAlt} className="" />
        </div>
      )}

      <a href={uri} className="image-with-link-btn">
        {title}
      </a>
    </div>
  );
};

export default ImageWithLink;
