import MicrositeHome from './components/MicrositeHome';


const App = () => {


    return (
        <>
            <MicrositeHome />

        </>


    );
};

export default App;