import React, { useEffect, useState } from "react";
import HeroWithImage from "./HeroWithImage";
import ImageWithLink from "./ImageWithLink";

const PageWithRightRail = ({ bundleData }) => {
  const [heroData, setHeroData] = useState(null);
  const [leftMainContentData, setLeftMainContentData] = useState(null);
  const [rightSectionData, setRightSectionData] = useState(null);
  const [heroImage, setHeroImageSrc] = useState("");
  const [paraImgs, setParaImgs] = useState({});
  const [rightParaImgs, setRightParaImgs] = useState({});

  const getImage = (url) => {
    const headers = new Headers({
      Accept: "application/vnd.api+json",
    });

    return fetch(url, { headers })
      .then((response) => response.json())
      .then((data) => {
        return data?.data?.attributes?.uri?.url; // Return the URL part of the data
      })
      .catch((err) => {
        console.log("There was an error accessing the API", err);
        throw err; // Re-throw the error if you want to handle it later
      });
  };

  const getHeroData = (data) => {
    if (data?.field_hero_rr?.data?.length > 0) {
      const url = data?.field_hero_rr?.links?.related?.href;
      const headers = new Headers({
        Accept: "application/vnd.api+json",
      });

      fetch(url, { headers })
        .then((response) => response.json())
        .then((data) => {
          setHeroData(data.data[0]);
          getImage(
            data.data[0].relationships?.field_hero_image_rr?.links?.related
              ?.href
          )
            .then((imageURL) => {
              setHeroImageSrc(imageURL);
            })
            .catch((error) => {
              console.error("Error fetching image:", error);
            });
        })
        .catch((err) =>
          console.log("There was an error accessing the API", err)
        );
    }
  };

  const getLeftMainContent = (data) => {
    if (data?.field_main_content_left?.data?.length > 0) {
      const url = data?.field_main_content_left?.links?.related?.href;
      const headers = new Headers({
        Accept: "application/vnd.api+json",
      });

      fetch(url, { headers })
        .then((response) => response.json())
        .then((data) => {
          setLeftMainContentData(data.data);
        })
        .catch((err) =>
          console.log("There was an error accessing the API", err)
        );
    }
  };

  const fetchParagraphImg = async (paraData, index, fn) => {
    try {
      const imgSrc = await getImage(
        paraData?.relationships?.field_image?.links?.related?.href
      );
      fn((prev) => ({ ...prev, [index]: imgSrc }));
    } catch (error) {
      console.error("Error fetching image:", error);
      return ""; // Return an empty string or a default value if there is an error
    }
  };

  const getRightSectionData = (data) => {
    if (data?.field_main_content_right?.data?.length > 0) {
      const url = data?.field_main_content_right?.links?.related?.href;
      const headers = new Headers({
        Accept: "application/vnd.api+json",
      });

      fetch(url, { headers })
        .then((response) => response.json())
        .then((data) => {
          setRightSectionData(data.data);
        })
        .catch((err) =>
          console.log("There was an error accessing the API", err)
        );
    }
  };
  useEffect(() => {
    {
      getHeroData(bundleData.relationships);
      getLeftMainContent(bundleData.relationships);
      getRightSectionData(bundleData.relationships);
    }
  }, []);

  useEffect(() => {
    if (leftMainContentData && leftMainContentData.length > 0) {
      leftMainContentData.forEach((element, index) => {
        fetchParagraphImg(element, index, setParaImgs);
      });
    }
  }, [leftMainContentData]);

  useEffect(() => {
    if (rightSectionData && rightSectionData.length > 0) {
      rightSectionData.forEach((element, index) => {
        fetchParagraphImg(element, index, setRightParaImgs);
      });
    }
  }, [rightSectionData]);

  return (
    <>
      <div className="rr-container">
        {heroData && (
          <HeroWithImage
            title={heroData.attributes.field_title}
            subtext={heroData.attributes.field_hero_sub_text_rr.value}
            imgAlt={heroData.relationships.field_hero_image_rr.data.meta.alt}
            imgSrc={heroImage}
          />
        )}
        <div id="sneakParent">
          <div className="container below-hero-grid">
            <section className="first-column">
              {leftMainContentData &&
                leftMainContentData.length > 0 &&
                leftMainContentData.map((mainContent, index) => {
                  switch (mainContent.type) {
                    case "paragraph--rte_rr":
                      return (
                        <div
                          className="rich-text"
                          dangerouslySetInnerHTML={{
                            __html: mainContent.attributes.field_rte_rr.value,
                          }}
                        ></div>
                      );
                    case "paragraph--image_link":
                      return paraImgs && paraImgs[index] ? (
                        <ImageWithLink
                          title={mainContent.attributes.field_link_rte.title}
                          uri={mainContent.attributes.field_link_rte.uri}
                          imgAlt={
                            mainContent.relationships.field_image.data.meta.alt
                          }
                          imgSrc={paraImgs[index]}
                        />
                      ) : (
                        <></>
                      );
                    default:
                      return null;
                  }
                })}
            </section>
            <aside className="second-column">
              {rightSectionData &&
                rightSectionData.length > 0 &&
                rightSectionData.map((mainContent, index) => {
                  switch (mainContent.type) {
                    case "paragraph--rte_rr":
                      return (
                        <div
                          className="rich-text"
                          dangerouslySetInnerHTML={{
                            __html: mainContent.attributes.field_rte_rr.value,
                          }}
                        ></div>
                      );
                    case "paragraph--image_link":
                      return rightParaImgs && rightParaImgs[index] ? (
                        <ImageWithLink
                          title={mainContent.attributes.field_link_rte.title}
                          uri={mainContent.attributes.field_link_rte.uri}
                          imgAlt={
                            mainContent.relationships.field_image.data.meta.alt
                          }
                          imgSrc={rightParaImgs[index]}
                        />
                      ) : (
                        <></>
                      );
                    default:
                      return null;
                  }
                })}
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageWithRightRail;
