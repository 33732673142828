import React from "react";
import { REACT_APP_DRUPAL_BASE_URL } from "../utils/config";

const HeroWithImage = ({ title, subtext, imgAlt, imgSrc }) => {
  return (
    <div className="hero-container">
      <section className="responsive-grid">
        <div className="first-div">
          <div>
            <h1>{title}</h1>
            {subtext && (
              <div
                className="rich-text hero-component-sub-text"
                dangerouslySetInnerHTML={{
                  __html: subtext,
                }}
              ></div>
            )}
          </div>
        </div>
        {imgAlt && (
          <div className="second-div">
            <img src={`${REACT_APP_DRUPAL_BASE_URL}${imgSrc}`} alt={imgAlt} className="hero-image" />
          </div>
        )}
      </section>
    </div>
  );
};

export default HeroWithImage;
