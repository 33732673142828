import React, { useEffect, useState } from "react";
import HeroWithImage from "./HeroWithImage";

const FullWidthPage = ({ bundleData }) => {
  const [heroData, setHeroData] = useState(null);
  const [heroImage, setHeroImageSrc] = useState("");
  const [mainContent, setMainContentData] = useState([]);
 
  const getImage = (url) => {
    const headers = new Headers({
      Accept: "application/vnd.api+json",
    });

    return fetch(url, { headers })
      .then((response) => response.json())
      .then((data) => {
        return data?.data?.attributes?.uri?.url; // Return the URL part of the data
      })
      .catch((err) => {
        console.log("There was an error accessing the API", err);
        throw err; // Re-throw the error if you want to handle it later
      });
  };

  const getHeroData = (data) => {
    if (data?.field_hero?.data?.length > 0) {
      const url = data?.field_hero?.links?.related?.href;
      const headers = new Headers({
        Accept: "application/vnd.api+json",
      });

      fetch(url, { headers })
        .then((response) => response.json())
        .then((data) => {
          setHeroData(data.data[0]);
          getImage(
            data.data[0].relationships.field_hero_image.links.related.href
          )
            .then((imageURL) => {
              setHeroImageSrc(imageURL);
            })
            .catch((error) => {
              console.error("Error fetching image:", error);
            });
          
        })
        .catch((err) =>
          console.log("There was an error accessing the API", err)
        );
    }
  };

  const fetchMainContent = (mainContent) => {
    if (mainContent?.data?.length > 0) {
      const url = mainContent?.links?.related?.href;
      const headers = new Headers({
        Accept: "application/vnd.api+json",
      });

      fetch(url, { headers })
        .then((response) => response.json())
        .then((data) => {
          setMainContentData(data.data);

        })
        .catch((err) =>
          console.log("There was an error accessing the API", err)
        );
    }
  }
  useEffect(() => {
    {
      getHeroData(bundleData.relationships);
      fetchMainContent(bundleData.relationships.field_main_content)
    }
  }, []);

  return (
    <div className="rr-container">
      {heroData && (
        <HeroWithImage
          title={heroData.attributes.field_hero_title}
          subtext={heroData.attributes.field_hero_sub_text.value}
          imgAlt={heroData.relationships.field_hero_image.data.meta.alt}
          imgSrc={heroImage}
        />
      )}
      <div className="container">
        {mainContent.length > 0 && mainContent.map(rteData => <div
                className="rich-text"
                dangerouslySetInnerHTML={{
                  __html: rteData.attributes.field_rte.value,
                }}
              ></div>)}

      </div>
    </div>
  );
};

export default FullWidthPage;
